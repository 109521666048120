<template>
  <v-card>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> Specular Evaluation </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2"> Slit Lamp Evaluation </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">Specular Evaluation</span>
          </v-card-title>
          <v-row class="ml-3 mr-5 pt-3 mb-3">
            <v-col cols="12" md="4">
              <v-file-input
                chips
                v-model="Getsignature"
                accept="image/*"
                label="Specular Evaluation Image*"
                @change="onFileChange1"
                :readonly="readOnlyField1 === true"
                :error="errr === false"
              />
            </v-col>
            <v-col>
              <v-col v-if="!signatureObj1 && SlitLampValue.specular_evaluation" cols="4">
                <img
                  v-img
                  style="width: 100%"
                  :src="!signatureObj1 ? `https://api.ebsr.in/${SlitLampValue.specular_evaluation}` : ''"
                />
              </v-col>
              <v-col v-if="!!signatureObj1" cols="4">
                <img v-img style="width: 100%" :src="signatureObj1 ? signatureObj1 : ''" />
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ml-3 mr-5 pt-3 mb-3">
            <v-col cols="12" md="4">
              <!-- <v-text-field v-model="SlitLampValue.os_id" label="Specular Date-Time" hide-details ></v-text-field> -->
              <v-datetime-picker 
                v-model="specularDateTimeValue" 
                date-format="dd-MM-yyyy" 
                time-format="HH:mm" 
                label="Specular Date-Time" 
                dense 
              >
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="SlitLampValue.specularTechnicianName" label="Specular Technician Name" hide-details ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="SlitLampValue.specularPachymetry" label="Pachymetry(microns)" hide-details ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-1">
            <v-col cols="12" md="12" class="text-right">
              <!-- <v-btn class="mr-3" color="primary" @click="formCFirst"> Save </v-btn> -->
              <v-btn color="primary" @click="samedata()"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">Slit Lamp Evaluation</span>
          </v-card-title>
          <v-row class="mx-4 pt-10">
            <v-col cols="12" md="4">
              <v-text-field v-model="SlitLampValue.os_id" label="Tissue ID" hide-details readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.from_penlight_exam"
                hide-details
                @change="itemdata()"
                :items="Penlight"
                label="Penlight Exam*"
                :readonly="readOnlyField1 === true"
                :error="errr1 === false"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4 mb-5">
      <v-col cols="12" md="4">
          <p class="body-2 mt-2 mb-0">Specular Evalution cell Count(per mm<sup>2</sup>):</p>
      </v-col>
      <v-col cols="12" md="4" >
          <v-text-field v-model="SlitLampValue.from_specular_evaluation" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
            
            <!-- <v-col cols="12" md="4">
              <v-text-field
                v-model="SlitLampValue.from_specular_evaluation"
                label="Specular Evalution cell Count(per mm)"
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-row>

          <v-card-title class="justify-start">
            <span class="font-weight-semibold">EPITHELIUM</span>
          </v-card-title>
          <v-row class="mx-4">
            <v-col cols="12">
              <v-radio-group
                label="Clear and Intact*:"
                row
                class="mt-0"
                v-model="SlitLampValue.epithelium_clear_and_intact"
                hide-details
                :readonly="readOnlyField1 === true"
                @change="ClearAndIntact()"
                :error="errr2 === false"
              >
                <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
                <v-radio name="Parents_name" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-4" v-if="SlitLampValue.epithelium_clear_and_intact == 'No'">
            <v-col cols="12" md="4">
              <v-autocomplete v-model="SlitLampValue.heze" dense :items="Heze" label="Heze*" :readonly="readOnlyField1 === true" :error="errr3 === false"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4" v-if="SlitLampValue.epithelium_clear_and_intact == 'No'">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.exposure"
                dense
                :items="Heze"
                label="Exposure*"
                @change="Exposure()"
                :readonly="readOnlyField1 === true"
                :error="errr4 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.exposure == 'Mild' ||
                SlitLampValue.exposure == 'Mild-Moderate' ||
                SlitLampValue.exposure == 'Moderate' ||
                SlitLampValue.exposure == 'Moderate-severe' ||
                SlitLampValue.exposure == 'severe'
              "
            >
              <v-autocomplete
                v-model="SlitLampValue.exposure_location"
                dense
                :items="Location"
                label="Exposure Location*"
                :readonly="readOnlyField1 === true"
                :error="errr5 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.exposure == 'Mild' ||
                SlitLampValue.exposure == 'Mild-Moderate' ||
                SlitLampValue.exposure == 'Moderate' ||
                SlitLampValue.exposure == 'Moderate-severe' ||
                SlitLampValue.exposure == 'severe'
              "
            >
              <v-text-field
                v-model="SlitLampValue.exposure_area"
                label="Exposure Area(% of total cornea)*"
                dense
                @keypress="onlyNumber"
                :readonly="readOnlyField1 === true"
                :error="errr6 ===false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4" v-if="SlitLampValue.epithelium_clear_and_intact == 'No'">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.sloughing_location"
                dense
                :items="Sloughing_Location"
                label="Sloughing Location*"
                @change="SloughingLocation()"
                :readonly="readOnlyField1 === true"
                :error="errr7 === false"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.sloughing_location == 'Center' ||
                SlitLampValue.sloughing_location == 'Para Central' ||
                SlitLampValue.sloughing_location == 'peripheral' ||
                SlitLampValue.sloughing_location == 'Mid-peripheral' ||
                SlitLampValue.sloughing_location == 'Diffuse'
              "
            >
              <v-text-field
                v-model="SlitLampValue.sloughing_area"
                label="Sloughing Area(% of total cornea)*"
                dense
                hide-details
                @keypress="onlyNumber"
                :readonly="readOnlyField1 === true"
                :error="errr8 === false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-radio-group
                label="Debris*:"
                row
                class="mt-0"
                v-model="SlitLampValue.debris"
                hide-details
                @change="debRis()"
                :readonly="readOnlyField1 === true"
                :error="errr9 === false"
              >
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.debris == 'Yes'">
              <v-text-field v-model="SlitLampValue.debris_description" label="If yes Describe" dense hide-details :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="SlitLampValue.epithelium_clear_and_intact_commetns"
                label="Comments"
                dense
                hide-details
                row-height="15"
                rows="2"
                :readonly="readOnlyField1 === true"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-card-title class="justify-start mt-8 mb-4">
            <span class="font-weight-semibold">STROMA</span>
          </v-card-title>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.stroma_clear_and_intact"
                label="Clear and Compact*"
                :items="Clear_and_Compact"
                dense
                :readonly="readOnlyField1 === true"
                :error="errr10 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete v-model="SlitLampValue.edema" dense :items="Heze" label="Edema" @change="edEma()" :readonly="readOnlyField1 === true">
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.edema == 'Mild' ||
                SlitLampValue.edema == 'Mild-Moderate' ||
                SlitLampValue.edema == 'Moderate' ||
                SlitLampValue.edema == 'Moderate-severe' ||
                SlitLampValue.edema == 'severe'
              "
            >
              <v-autocomplete
                v-model="SlitLampValue.edema_location"
                dense
                hide-details
                :items="Edema_Loctaion"
                label="Edema Loctaion"
                :readonly="readOnlyField1 === true"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.arcus"
                dense
                hide-details
                :items="Heze"
                label="Arcus*"
                @change="arCus()"
                :readonly="readOnlyField1 === true"
                :error="errr11 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="SlitLampValue.arcus_clear_zone"
                label="Clear Zone(mm in Diameter)*"
                dense
                hide-details
                :readonly="readOnlyField1 === true"
                :error="errr12 === false"
              ></v-text-field> 
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.stroma_opacities1"
                :items="Opacities_Items"
                label="Opacities 1*"
                hide-details
                @change="Opacities_One()"
                :readonly="readOnlyField1 === true"
                :error="errr13 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row
            class="mx-4"
            v-if="
              SlitLampValue.stroma_opacities1 == 'Surgical(IOL)' ||
              SlitLampValue.stroma_opacities1 == 'Lasik' ||
              SlitLampValue.stroma_opacities1 == 'Other'
            "
          >
            <v-col cols="12" md="2" class="pr-0">
              <p class="mt-5 mb-0">Length/Diameter*</p>
            </v-col>
            <v-col cols="12" md="1" class="pl-0">
              <v-text-field v-model="SlitLampValue.stroma_opacities1_length" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true" :error="errr14 === false">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pr-0">
              <p class="mt-5 mb-0">mm Location, at*:</p>
            </v-col>
            <v-col cols="12" md="2" class="pl-0">
              <v-text-field v-model="SlitLampValue.stroma_opacities1_location" hide-details :readonly="readOnlyField1 === true" :error="errr15 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pr-0">
              <p class="mt-5 mb-0">mmon button/from limbus Depth (%)*:</p>
            </v-col>
            <v-col cols="12" md="1" class="pl-0">
              <v-text-field v-model="SlitLampValue.stroma_opacities1_depth" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true" :error="errr16 === false">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.stroma_opacities2"
                hide-details
                :items="Opacities_Items"
                label="Opacities 2*"
                @change="Opacities_Two()"
                :readonly="readOnlyField1 === true"
                :error="errr17 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row
            class="mx-4"
            v-if="
              SlitLampValue.stroma_opacities2 == 'Surgical(IOL)' ||
              SlitLampValue.stroma_opacities2 == 'Lasik' ||
              SlitLampValue.stroma_opacities2 == 'Other'
            "
          >
            <v-col cols="12" md="2" class="pr-0">
              <p class="mt-5 mb-0">Length/Diameter*</p>
            </v-col>
            <v-col cols="12" md="1" class="pl-0">
              <v-text-field v-model="SlitLampValue.stroma_opacities2_length" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true" :error="errr18 === false">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pr-0">
              <p class="mt-5 mb-0">mm Location, at*:</p>
            </v-col>
            <v-col cols="12" md="2" class="pl-0">
              <v-text-field v-model="SlitLampValue.stroma_opacities2_location" hide-details :readonly="readOnlyField1 === true" :error="errr19 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pr-0">
              <p class="mt-5 mb-0">mmon button/from limbus Depth (%)*:</p>
            </v-col>
            <v-col cols="12" md="1" class="pl-0">
              <v-text-field v-model="SlitLampValue.stroma_opacities2_depth" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true" :error="errr20 === false">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-4">
            <v-col cols="12" md="4">
              <v-radio-group label="Infiltrate*:" row class="mt-0" v-model="SlitLampValue.infiltrate" hide-details dense :readonly="readOnlyField1 === true" :error="errr21 === false">
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-4">
            <v-col cols="12" md="4">
              <v-radio-group
                label="Striae*:"
                row
                class="mt-0"
                v-model="SlitLampValue.striae"
                hide-details
                dense
                @change="StriaeFun()"
                :readonly="readOnlyField1 === true"
                :error="errr22 === false"
              >
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.striae == 'Yes'">
              <v-autocomplete
                v-model="SlitLampValue.striae_location"
                dense
                hide-details
                :items="Striae_Location"
                label="Striae Location*"
                :readonly="readOnlyField1 === true"
                :error="errr23 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.striae == 'Yes'">
              <v-autocomplete
                v-model="SlitLampValue.striae_relative"
                dense
                hide-details
                :items="Relative"
                label="Relative of Striae*"
                :readonly="readOnlyField1 === true"
                :error="errr24 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-6">
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="SlitLampValue.stroma_clear_and_compac_commetns"
                label="Comments"
                dense
                row-height="15"
                rows="2"
                :readonly="readOnlyField1 === true"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-card-title class="justify-start mt-4 mb-4">
            <span class="font-weight-semibold">DESCEMETS</span>
          </v-card-title>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.descemets_folds"
                label="Folds*"
                :items="Heze"
                dense
                :readonly="readOnlyField1 === true"
                @change="Folds()"
                :error="errr25 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.descemets_folds == 'Mild' ||
                SlitLampValue.descemets_folds == 'Mild-Moderate' ||
                SlitLampValue.descemets_folds == 'Moderate' ||
                SlitLampValue.descemets_folds == 'Moderate-severe' ||
                SlitLampValue.descemets_folds == 'severe'
              "
            >
              <v-autocomplete
                v-model="SlitLampValue.descemets_folds_relative"
                dense
                :items="Relative_folds"
                label="Relative of folds*"
                :readonly="readOnlyField1 === true"
                :error="errr26 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.descemets_defects"
                dense
                :readonly="readOnlyField1 === true"
                :items="Defects"
                :error="errr27 === false"
                label="Defects*"
                @change="deFects()"
                
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.descemets_defects == 'Descemets tears' ||
                SlitLampValue.descemets_defects == 'Defect at IOL scar'
              "
            >
              <v-text-field v-model="SlitLampValue.descemets_defects_description" label="If yes Describe" dense :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="12">
              <v-textarea v-model="SlitLampValue.descemets_comments" label="Comments" dense row-height="15" rows="2" :readonly="readOnlyField1 === true">
              </v-textarea>
            </v-col>
          </v-row>

          <v-card-title class="justify-start mt-4 mb-4">
            <span class="font-weight-semibold">ENDOTHELIUM</span>
          </v-card-title>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.endothelium_stress_lines"
                label="Stress Lines*"
                :items="['Yes', 'No']"
                :readonly="readOnlyField1 === true"
                :error="errr28 === false"
                dense
                @change="StressLines()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.endothelium_stress_lines == 'Yes'">
              <v-autocomplete
                v-model="SlitLampValue.endothelium_stress_lines_location"
                dense
                :readonly="readOnlyField1 === true"
                :items="Relative_Location"
                :error="errr29 === false"
                label="Stress Location"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.endothelium_stress_lines == 'Yes'">
              <v-autocomplete
                v-model="SlitLampValue.endothelium_stress_lines_relative"
                dense
                :readonly="readOnlyField1 === true"
                :items="Relative_Stress"
                :error="errr30 === false"
                label="Relative of Stress lines*"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                dense
                v-model="SlitLampValue.endothelium_defects"
                :items="['Yes', 'No']"
                label="Defects*"
                :readonly="readOnlyField1 === true"
                @change="defectsEndo()"
                :error="errr31 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.endothelium_defects == 'Yes'">
              <v-text-field v-model="SlitLampValue.endothelium_defects_description" label="If yes Describe" dense :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.endothelium_cell_dropoult"
                dense
                :readonly="readOnlyField1 === true"
                :items="Heze"
                label="Cell Dropoult"
                @change="cellDrop()"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                SlitLampValue.endothelium_cell_dropoult == 'Mild' ||
                SlitLampValue.endothelium_cell_dropoult == 'Mild-Moderate' ||
                SlitLampValue.endothelium_cell_dropoult == 'Moderate' ||
                SlitLampValue.endothelium_cell_dropoult == 'Moderate-severe' ||
                SlitLampValue.endothelium_cell_dropoult == 'severe'
              "
            >
              <v-autocomplete
                v-model="SlitLampValue.endothelium_cell_dropoult_location"
                dense
                :readonly="readOnlyField1 === true"
                :items="Cell_Location"
                label="Cell Dropoult Location"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.endothelium_polymegathism"
                dense
                :readonly="readOnlyField1 === true"
                :error="errr32 === false"
                :items="Heze"
                label="Polymegathism*"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete v-model="SlitLampValue.endothelium_pleomorphism" dense :items="Heze" label="Pleomorphism*" :readonly="readOnlyField1 === true" :error="errr33 === false">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="12">
              <v-textarea v-model="SlitLampValue.endothelium_comments" label="Comments" dense row-height="15" rows="2" :readonly="readOnlyField1 === true">
              </v-textarea>
            </v-col>
          </v-row>

          <v-card-title class="justify-start mt-4 mb-4">
            <span class="font-weight-semibold">ADDITONAL INFORMATION</span>
          </v-card-title>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.add_info_jaundice"
                label="Jaundice*"
                :items="Heze"
                dense
                :readonly="readOnlyField1 === true"
                :error="errr34 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="SlitLampValue.add_info_lens"
                dense
                label="Lens*"
                readonly
               :error="errr35 === false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.add_info_scleral_rim_evalution"
                dense
                :items="Scleral"
                label="Scleral rim evaluation*"
                :readonly="readOnlyField1 === true"
                :error="errr36 ===  false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                dense
                v-model="SlitLampValue.add_info_scleral_rim_circumferentially"
                :items="['Yes', 'No']"
                label="Scleral rim 2mm or Greater Circumferentially*"
                @change="ScleralFun()"
                :readonly="readOnlyField1 === true"
                :error="errr37 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="SlitLampValue.add_info_scleral_rim_circumferentially == 'No'">
              <v-text-field v-model="SlitLampValue.add_info_scleral_rim_description" label="If no, describe" dense :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="SlitLampValue.approval_evaluation_name"
                label="Name"
                dense
                :readonly="readOnlyField1 === true"
                :items="approvalNames"
                v-on="approvalNameEmailFun()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="SlitLampValue.approval_evaluation_email"
                label="Email"
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-title class="justify-start mt-4 mb-4">
            <span class="font-weight-semibold">APPROVAL</span>
          </v-card-title>

          <v-row class="mx-4">
            <v-col cols="12" md="8">
              <v-radio-group
                label="This is Cornea Suitable for Transplant?"
                row
                class="mt-0"
                v-model="SlitLampValue.approval_suitable"
                hide-details
                :disabled="readOnlyField2 === true"
                :readonly="readOnlyField1 === true">
                <v-radio @click="selected_no_slit = []" name="Parents_name" label="Yes" value="Yes"></v-radio>
                <v-radio @click="selected_yes_slit = []" name="Parents_name" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-4" v-if="SlitLampValue.approval_suitable == 'Yes'">
            <v-col cols="12" md="3">
              <p class="body-2 mt-5 mb-0 mb-3">Suitable for Only those checked:</p>
            </v-col>
            <v-col cols="12" md="9" row>
              <template v-for="obj in slit_yes_arr">
                <v-checkbox v-model="selected_yes_slit" :label="obj" :value="obj" hide-details :readonly="readOnlyField1 === true" :disabled="readOnlyField2 === true"></v-checkbox>
              </template>
            </v-col>
          </v-row>
          <v-row class="mx-4" v-if="SlitLampValue.approval_suitable == 'No'">
            <v-col cols="12" md="3" >
              <p class="body-2 mt-5 mb-0 mb-3">Not Suitable for any type of Transplant, due to:</p>
            </v-col>
            <v-col cols="12" md="3">
              <template v-for="obj in slit_no_arr">
                <v-checkbox v-model="selected_no_slit" :label="obj" :value="obj" hide-details :readonly="readOnlyField1 === true" :disabled="readOnlyField2 === true"></v-checkbox>
              </template>
            </v-col>
            <v-col cols="12" md="3" class="mt-10 pt-8">
              <v-autocomplete
                dense
                @change="rest()"
                :items="Stroma"
                v-if="selected_no_slit.includes('Stroma')"
                label="Stroma"
                v-model="SlitLampValue.stroma_type"
                :disabled="readOnlyField2 === true"
                :readonly="readOnlyField1 === true"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="mt-10 pt-8" v-if="!show_upload_form">
              <v-text-field
                label="Other"
                :disabled="readOnlyField2 === true"
                :readonly="readOnlyField1 === true"
                v-if="selected_no_slit.includes('Stroma') && SlitLampValue.stroma_type == 'Other'"
                v-model="SlitLampValue.approval_if_not_suitable_type_other"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-4">
            <!-- <v-col cols="12" md="2">
        <img v-img style="width: 100%" :src="`https://api.ebsr.in/${SlitLampValue.upload_form}`" />
      </v-col> -->
            <v-col v-if="!signatureObj && SlitLampValue.upload_form && !show_upload_form" cols="3">
              <img
                v-img
                style="width: 82%"
                :src="!signatureObj ? `https://api.ebsr.in/${SlitLampValue.upload_form}` : ''"
              />
            </v-col>
            <v-col v-if="!!signatureObj && !show_upload_form" cols="3">
              <img v-img style="width: 82%" :src="signatureObj ? signatureObj : ''" />
            </v-col>

            <v-col cols="12" md="3" v-if="!show_upload_form">
              <v-file-input
                v-model="signature"
                chips
                accept="image/*"
                label="Cornea Suitability determined by"
                @change="onFileChange"
                :disabled="readOnlyField2 === true"
                :readonly="readOnlyField1 === true"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-datetime-picker v-model="approvalDateTime" date-format="dd-MM-yyyy" time-format="HH:mm" label="Date and Time" dense :readonly="readOnlyField1 === true" :disabled="readOnlyField2 === true">
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" md="3" class="mt-4">
              <v-text-field v-model="SlitLampValue.approvalName" label="Name" dense :readonly="readOnlyField1 === true" :disabled="readOnlyField2 === true">
              </v-text-field>
            </v-col>


            <!-- Uplaod printed form  -->

            <v-col cols="12" md="4" v-if="show_upload_form">
              <v-file-input
                v-model="tissue_evaluation_upload_form"
                chips
                accept="image/*"
                label="Upload Form"
                @change="onFileChange2"
                :disabled="readOnlyField2 === true"
                :readonly="readOnlyField1 === true"
              />
            </v-col>


            <v-col v-if="!tissue_evaluation_upload_formObj && SlitLampValue.tissue_evaluation_upload_form && show_upload_form" cols="3">
              <img
                v-img
                style="width: 82%"
                :src="`https://api.ebsr.in/${SlitLampValue.tissue_evaluation_upload_form}`"
              />
            </v-col>

            <v-col v-if="!!tissue_evaluation_upload_formObj" cols="3">
              <img v-img style="width: 82%" :src="tissue_evaluation_upload_formObj ? tissue_evaluation_upload_formObj : ''" />
            </v-col>

          </v-row>
          <v-row class="mx-4">
            <v-col cols="12" md="12">
              <v-textarea
                v-model="SlitLampValue.approval_evaluation_comments"
                label="Evaluation Comments"
                dense
                row-height="15"
                rows="2"
                :disabled="readOnlyField2 === true"
                :readonly="readOnlyField1 === true"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-card-actions class="mt-4 pb-8 d-block">
            <v-btn color="primary" text @click="e1 = 1"> Previous </v-btn>
            <!-- <v-btn color="primary" class="float-right" 
            v-if="saveBtn" 
            @click="same()"> Save </v-btn> -->

            <v-btn color="primary" class="float-right" 
      
            @click="same()"> Save </v-btn>
            <a v-if="printBtn" target="_blank" :href="`https://api.ebsr.in/${SlitLampValue.pdf_path}`">
            <v-btn color="primary" class="float-right" v-if="printBtn" @click="printDocument()"> Print </v-btn>
          </a>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-stepper>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import SlitLampService from '@/service/SlitLamp.service'
import moment from 'moment'
import axios from 'axios'
const baseUrl = 'https://api.ebsr.in/'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const userId = localData.user_id

export default {
  data() {
    return {
      errr: true,
      errr1: true,
      errr2: true,
      errr3: true,
      errr4: true,
      errr5: true,
      errr6: true,
      errr7: true,
      errr8: true,
      errr9: true,
      errr10: true,
      errr11: true,
      errr12: true,
      errr13: true,
      errr14: true,
      errr15: true,
      errr16: true,
      errr17: true,
      errr18: true,
      errr19: true,
      errr20: true,
      errr21: true,
      errr22: true,
      errr23: true,
      errr24: true,
      errr25: true,
      errr26: true,
      errr27: true,
      errr28: true,
      errr29: true,
      errr30: true,
      errr31: true,
      errr32: true,
      errr33: true,
      errr34: true,
      errr35: true,
      errr36: true,
      errr37: true,
      userrr :localData.Role_id,
      formtype:'',
      e1: '1',
      dateitem:'',
      snackbar: false,
      show_upload_form: false,
      snackbarText: '',
      timeout: 2000,
      slit_yes_arr: ['PK', 'EK', 'ALK', 'KLA', 'K-Pro', 'Therapeutic'],
      selected_yes_slit: [],
      slit_no_arr: ['Epithelium', 'Stroma', "Descemet's Membrane", 'Endothelium'],
      selected_no_slit: [],
      Slit_not_suitable: '',
      signature: null,
      tissue_evaluation_upload_form: null,
      Getsignature:null,
      image: null,
      PK: false,
      readOnlyField1:false,
      readOnlyField2:false,
      Stroma: '',
      not_suitable: '',
      Heze: ['None', 'Mild', 'Mild-Moderate', 'Moderate', 'Moderate-severe', 'severe'],
      //Exposure: ["None","Mild","Mild-Moderate","Moderate","Moderate-severe","severe",],
      Penlight: ['Phakic', 'Pseudophakic', 'Aphakic'],
      Location: ['Center', 'Para Central', 'peripheral', 'Mid-peripheral', 'Diffuse', 'Band'],
      Sloughing_Location: ['None', 'Center', 'Para Central', 'peripheral', 'Mid-peripheral', 'Diffuse'],
      Clear_and_Compact: ['clear only', 'Compact only', 'clear and Compact', 'Neither'],
      //Edema: ["None","mild","mild-moderate","moderate","moderate-servere","servere",],
      Edema_Loctaion: ['Center', 'Para Central', 'peripheral', 'Mid-peripheral', 'Diffuse'],
      //Arcus: ["None","mild","mild-moderate","moderate","moderate-servere","servere",],
      Opacities_Items: ['Surgical(IOL)', 'Lasik', 'Other', 'None'],
      //Opacities_2: ["Surgical(IOL)", "Lasik", "Other", "None"],
      Striae_Location: ['Central', 'para central', 'Peripheral', 'Mid-Peripheral', 'Diffuse'],
      Relative: ['Few', 'Few-Several', 'Several', 'Several-Numerous', 'Numerous'],
      //Folds: [ "None","Mild","Mild-Moderate","Moderate", "Moderate-Severe","Severe",],
      Relative_folds: ['Few', 'Few-Several', 'Several', 'Several-Numerous', 'Numerous'],
      Defects: ['None', 'Descemets tears', 'Defect at IOL scar'],
      Relative_Location: ['Central', 'para central', 'Peripheral', 'Mid-Peripheral', 'Diffuse'],
      Relative_Stress: ['Few', 'Few-Several', 'Several', 'Several-Numerous', 'Numerous'],
      //Call_Dropoult: ["None","Mild","Mild-Moderate","Moderate","Moderate-Severe","Severe",],
      Cell_Location: ['Central', 'para central', 'Peripheral', 'Mid-Peripheral', 'Diffuse'],
      //Polymegathism: ["None","Mild","Mild-Moderate","Moderate","Moderate-Severe","Severe",],
      //Pleomorphism: ["None","Mild","Mild-Moderate","Moderate","Moderate-Severe","Severe",],
      //Jaundice: ["None","Mild","Mild-Moderate","Moderate","Moderate-Severe","Severe",],
      Lens: ['Phakic', 'Pseudophakic', 'Aphakic'],
      Scleral: ['Regular', 'Irregular'],
      Stroma: ['Prior refractive surgery', 'Scar', 'Infiltrate', 'Foreign', 'Body', 'Other'],
      textFieldProps: {
        outlined: true,
        dense: true,
        'hide-details': true,
      },
      signatureObj1: '',
      tissue_evaluation_upload_formObj:'',
      signatureObj: '',
      saveBtn:true,
      printBtn: false,
      SlitLampValue: {
        tissue_evaluation_upload_form: '',
        epithelium_clear_and_intact: '',
        heze: '',
        approval_date_time: '',
        approval_if_not_suitable_type_other: '',
        approval_evaluation_comments: '',
        upload_form: '',
        exposure: '',
        exposure_location: '',
        exposure_area: '',
        sloughing_location: '',
        sloughing_area: '',
        debris: '',
        debris_description: '',
        edema: '',
        edema_location: '',
        arcus: '',
        arcus_clear_zone: '',
        stroma_opacities1: '',
        stroma_opacities1_length: '',
        stroma_opacities1_location: '',
        stroma_opacities1_depth: '',
        stroma_opacities2: '',
        stroma_opacities2_length: '',
        stroma_opacities2_location: '',
        stroma_opacities2_depth: '',
        striae: '',
        striae_location: '',
        striae_relative: '',
        descemets_folds: '',
        descemets_folds_relative: '',
        descemets_defects: '',
        descemets_defects_description: '',
        endothelium_stress_lines: '',
        endothelium_stress_lines_location: '',
        endothelium_stress_lines_relative: '',
        endothelium_defects: '',
        endothelium_defects_description: '',
        endothelium_cell_dropoult: '',
        endothelium_cell_dropoult_location: '',
        add_info_scleral_rim_circumferentially: '',
        add_info_scleral_rim_description: '',
        od_id: '',
        from_penlight_exam: '',
        from_specular_evaluation: '',
        epithelium_clear_and_intact_commetns: '',
        stroma_clear_and_intact: '',
        infiltrate: '',
        endothelium_polymegathism: '',
        stroma_clear_and_compac_commetns: '',
        descemets_comments: '',
        endothelium_pleomorphism: '',
        endothelium_comments: '',
        add_info_jaundice: '',
        add_info_lens: '',
        add_info_scleral_rim_evalution: '',
        approval_suitable: '',
        specular_evaluation: '',
        stroma_type:'',
        approvalName: '',
        specularDateTime: '',
        specularTechnicianName: '',
        specularPachymetry: '',
        approval_evaluation_name: '',
        approval_evaluation_email: '',
      },
      approvalNames: [],
      approvalNameEmail: [],
    }
  },

  computed: {
    approvalDateTime: {
      get() {
        return this.SlitLampValue.approval_date_time
          ? moment.utc(this.SlitLampValue.approval_date_time).format('DD-MM-YYYY HH:mm')
          : ''
      },
      set(value) {
        this.$set(
          this.SlitLampValue,
          'approval_date_time',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },

    specularDateTimeValue: {
      get() {
        return this.SlitLampValue.specularDateTime ? moment.utc(this.SlitLampValue.specularDateTime).format('DD-MM-YYYY HH:mm') : ''
      },
      set(value) {
        this.$set(
          this.SlitLampValue,
          'specularDateTime',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },
  },

  watch: {
    options: {
      handler() {
        this.getSlitLamp()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getSlitLamp()
    this.getdonorList()
    this.main()
    this.checkTissueEvaluatorLogin()
  },

  methods: {

    approvalNameEmailFun(){
      var nameVal = this.SlitLampValue.approval_evaluation_name
      var emailVal = this.approvalNameEmail.filter(function (el){
        return el.name == nameVal
      });
      if(emailVal.length > 0){
        this.SlitLampValue.approval_evaluation_email = emailVal[0].email
      }
    },

    printDocument(){
      this.show_upload_form = true;
      this.saveBtn = true;
      this.printBtn = false;
    },

    checkTissueEvaluatorLogin(){
      const { userDesignation } = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));;
      console.log('gashgaj', userDesignation);
      if(userDesignation == 'Tissue Evaluator'){
        this.saveBtn = false;
        this.printBtn = true;
      }
    },

    main(){
      if(this.userrr == 2){
        this.readOnlyField2 = true
      }
    },
    
    itemdata(){
      if(this.SlitLampValue.from_penlight_exam == this.SlitLampValue.from_penlight_exam){
        this.SlitLampValue.add_info_lens = this.SlitLampValue.from_penlight_exam
      }
    },

    samedata(){
      if(this.SlitLampValue.specular_evaluation == "" || this.SlitLampValue.specular_evaluation == null){
        this.errr = false
        return false,
        this.snackbarText = "Please Select Specular evaluation.",
        this.snackbar = true
      } 
      else if(this.SlitLampValue.specularDateTime == '' || this.SlitLampValue.specularDateTime == 'Invalid date' || this.SlitLampValue.specularDateTime === null || this.SlitLampValue.specularDateTime === undefined){
        return false,
        this.snackbarText = "Please Select Specular Date and Time.",
        this.snackbar = true
      }
      else if(this.SlitLampValue.specularTechnicianName == '' || this.SlitLampValue.specularTechnicianName === null || this.SlitLampValue.specularTechnicianName === undefined){
        return false,
        this.snackbarText = "Please Select Specular Technician Name.",
        this.snackbar = true
      }
      else if(this.SlitLampValue.specularPachymetry == '' || this.SlitLampValue.specularPachymetry === null || this.SlitLampValue.specularPachymetry === undefined){
        return false,
        this.snackbarText = "Please Select Specular Pachymetry.",
        this.snackbar = true
      }
      else{
        this.errr = true
        this.e1 = 2
      }
    },

    same(){
       if(this.SlitLampValue.from_penlight_exam == "" || this.SlitLampValue.from_penlight_exam == null){
        this.errr1 = false
        return false,
        this.snackbarText = "Please Select Penlight Exam.",
        this.snackbar = true
      }
      // if(this.SlitLampValue.from_specular_evaluation == "" || this.SlitLampValue.from_specular_evaluation == null){
      //   return false,
      //   this.snackbarText = "Please Fill the Specular Evalution cell Count(per mm2).",
      //   this.snackbar = true
      // }
      if(this.SlitLampValue.epithelium_clear_and_intact == "" || this.SlitLampValue.epithelium_clear_and_intact == null){
        this.errr2 = false
        return false,
        this.snackbarText = "Please Fill the epithelium clear and intact.",
        this.snackbar = true
      }if(this.SlitLampValue.epithelium_clear_and_intact == 'No'){
        if(this.SlitLampValue.heze == "" || this.SlitLampValue.heze == null){
          this.errr3 = false
        return false,
        this.snackbarText = "Please Fill the Heze.",
        this.snackbar = true
        }if(this.SlitLampValue.exposure == "" || this.SlitLampValue.exposure == null){
          this.errr4 =false
        return false,
        this.snackbarText = "Please Fill the Exposure.",
        this.snackbar = true
        }if(this.SlitLampValue.exposure != "None"){
          if(this.SlitLampValue.exposure_location == "" || this.SlitLampValue.exposure_location == null){
            this.errr5 = false
          return false,
          this.snackbarText = "Please Fill the Exposure Location.",
          this.snackbar = true
          }if(this.SlitLampValue.exposure_area == "" || this.SlitLampValue.exposure_area == null){
            this.errr6 = false
          return false,
          this.snackbarText = "Please Fill the Exposure Area.",
          this.snackbar = true
          }else{
            this.errr5 = true
            this.errr6 = true
          }
        }if(this.SlitLampValue.sloughing_location == "" || this.SlitLampValue.sloughing_location == null){
          this.errr7 = false
          return false,
          this.snackbarText = "Please Fill the Sloughing location.",
          this.snackbar = true
        }if(this.SlitLampValue.sloughing_location != "None"){
          if(this.SlitLampValue.sloughing_area == "" || this.SlitLampValue.sloughing_area == null){
            this.errr8 = false
          return false,
          this.snackbarText = "Please Fill the Sloughing area.",
          this.snackbar = true
          }else{
            this.errr8 = true
          }
        }else{
          this.errr3 =true
          this.errr4 =true
          this.errr7 = true
        }
      }




      if(this.SlitLampValue.debris == "" || this.SlitLampValue.debris == null){
        this.errr9 = false
        return false,
        this.snackbarText = "Please Fill the debris.",
        this.snackbar = true
      }
      if(this.SlitLampValue.stroma_clear_and_intact == "" || this.SlitLampValue.stroma_clear_and_intact == null){
        this.errr10 = false
        return false,
        this.snackbarText = "Please Fill the stroma clear and intact.",
        this.snackbar = true
      }
      if(this.SlitLampValue.arcus == "" || this.SlitLampValue.arcus == null){
        this.errr11 = false
        return false,
        this.snackbarText = "Please Fill the arcus.",
        this.snackbar = true
      }
      // if(this.SlitLampValue.arcus != "None"){
        if(this.SlitLampValue.arcus_clear_zone == "" || this.SlitLampValue.arcus_clear_zone == null){
          this.errr12 = false
        return false,
        this.snackbarText = "Please Fill the arcus clear zone.",
        this.snackbar = true
        }else{
           this.errr12 = true
        }
      // }


      // if(this.SlitLampValue.arcus == "None"){
      //   this.SlitLampValue.arcus_clear_zone = "10mm"
      // }

      if(this.SlitLampValue.stroma_opacities1 == "" || this.SlitLampValue.stroma_opacities1 == null){
        this.errr13 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 1.",
        this.snackbar = true
      }if(this.SlitLampValue.stroma_opacities1 != "None"){
        if(this.SlitLampValue.stroma_opacities1_length == "" || this.SlitLampValue.stroma_opacities1_length == null){
          this.errr14 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 1 Length.",
        this.snackbar = true
        }if(this.SlitLampValue.stroma_opacities1_location == "" || this.SlitLampValue.stroma_opacities1_location == null){
          this.errr15 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 1 Location.",
        this.snackbar = true
        }if(this.SlitLampValue.stroma_opacities1_depth == "" || this.SlitLampValue.stroma_opacities1_depth == null){
          this.errr16 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 1 Depth.",
        this.snackbar = true
        }else{
          this.errr14 = true
          this.errr15 = true
          this.errr16 = true
        }
      }


      if(this.SlitLampValue.stroma_opacities2 == "" || this.SlitLampValue.stroma_opacities2 == null){
        this.errr17 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 2.",
        this.snackbar = true
      }if(this.SlitLampValue.stroma_opacities2 != "None"){
         if(this.SlitLampValue.stroma_opacities2_length == "" || this.SlitLampValue.stroma_opacities2_length == null){
          this.errr18 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 2 Length.",
        this.snackbar = true
        }if(this.SlitLampValue.stroma_opacities2_location == "" || this.SlitLampValue.stroma_opacities2_location == null){
         this.errr19 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 2 Location.",
        this.snackbar = true
        }if(this.SlitLampValue.stroma_opacities2_depth == "" || this.SlitLampValue.stroma_opacities2_depth == null){
          this.errr20 = false
        return false,
        this.snackbarText = "Please Fill the Stroma Opacities 2 Depth.",
        this.snackbar = true
        }else{
          this.errr18 = true
          this.errr19 = true
          this.errr20 = true
        }
      }

      if(this.SlitLampValue.infiltrate == "" || this.SlitLampValue.infiltrate == null){
        this.errr21 = false
        return false,
        this.snackbarText = "Please Fill the infiltrate.",
        this.snackbar = true
      }
      if(this.SlitLampValue.striae == "" || this.SlitLampValue.striae == null){
        this.errr22 = false
        return false,
        this.snackbarText = "Please Fill the striae.",
        this.snackbar = true
      }if(this.SlitLampValue.striae == "Yes"){
        if(this.SlitLampValue.striae_location == "" || this.SlitLampValue.striae_location == null){
          this.errr23 = false
        return false,
        this.snackbarText = "Please Fill the striae Location.",
        this.snackbar = true
        }
        if(this.SlitLampValue.striae_relative == "" || this.SlitLampValue.striae_relative == null){
          this.errr24 = false
        return false,
        this.snackbarText = "Please Fill the striae relative.",
        this.snackbar = true
        }else{
          this.errr23 = true
          this.errr24 = true
        }
      }


      if(this.SlitLampValue.descemets_folds == "" || this.SlitLampValue.descemets_folds == null){
        this.errr25 = false
        return false,
        this.snackbarText = "Please Fill the folds.",
        this.snackbar = true
      }if(this.SlitLampValue.descemets_folds != "None"){
        if(this.SlitLampValue.descemets_folds_relative == "" || this.SlitLampValue.descemets_folds_relative == null){
          this.errr26 = false
           return false,
        this.snackbarText = "Please Fill the folds Relative.",
        this.snackbar = true
        }else{
          this.errr26 = true
        }
      }


      if(this.SlitLampValue.descemets_defects == "" || this.SlitLampValue.descemets_defects == null){
        this.errr27 = false
        return false,
        this.snackbarText = "Please Fill the defects.",
        this.snackbar = true
      }
      if(this.SlitLampValue.endothelium_stress_lines == "" || this.SlitLampValue.endothelium_stress_lines == null){
        this.errr28 = false
        return false,
        this.snackbarText = "Please Fill the Stress Lines.",
        this.snackbar = true
      }if(this.SlitLampValue.endothelium_stress_lines == "Yes"){
        if(this.SlitLampValue.endothelium_stress_lines_location == "" || this.SlitLampValue.endothelium_stress_lines_location == null){
          this.errr29 = false
        return false,
        this.snackbarText = "Please Fill the Stress Lines Location.",
        this.snackbar = true
        }
        if(this.SlitLampValue.endothelium_stress_lines_relative == "" || this.SlitLampValue.endothelium_stress_lines_relative == null){
          this.errr30 = false
        return false,
        this.snackbarText = "Please Fill the Stress Lines Relative.",
        this.snackbar = true
        }else{
          this.errr29 = true
          this.errr30 = true
        }
        
      }

 

      if(this.SlitLampValue.endothelium_defects == "" || this.SlitLampValue.endothelium_defects == null){
        this.errr31 = false
        return false,
        this.snackbarText = "Please Fill the Defects.",
        this.snackbar = true
      }
      if(this.SlitLampValue.endothelium_polymegathism == "" || this.SlitLampValue.endothelium_polymegathism == null){
        this.errr32 = false
        return false,
        this.snackbarText = "Please Fill the Polymegathism.",
        this.snackbar = true
      }
      if(this.SlitLampValue.endothelium_pleomorphism == "" || this.SlitLampValue.endothelium_pleomorphism == null){
        this.errr33 = false
        return false,
        this.snackbarText = "Please Fill the Pleomorphism.",
        this.snackbar = true
      }
      if(this.SlitLampValue.add_info_jaundice == "" || this.SlitLampValue.add_info_jaundice == null){
        this.errr34 = false
        return false,
        this.snackbarText = "Please Fill the Jaundice.",
        this.snackbar = true
      }
      if(this.SlitLampValue.add_info_lens == "" || this.SlitLampValue.add_info_lens == null){
        this.errr35 = false
        return false,
        this.snackbarText = "Please Fill the lens.",
        this.snackbar = true
      }
      if(this.SlitLampValue.add_info_scleral_rim_evalution == "" || this.SlitLampValue.add_info_scleral_rim_evalution == null){
        this.errr36 = false
        return false,
        this.snackbarText = "Please Fill the Scleral rim evalution.",
        this.snackbar = true
      }
      if(this.SlitLampValue.add_info_scleral_rim_circumferentially == "" || this.SlitLampValue.add_info_scleral_rim_circumferentially == null){
        this.errr37 = false
        return false,
        this.snackbarText = "Please Fill the Scleral rim 2mm or Greater Circumferentially.",
        this.snackbar = true
      }
      else{
        this.errr1 =true
          this.errr2 =true
        this.errr9 = true
        this.errr10 = true
        this.errr11 = true 
         this.errr13 = true
         this.errr17 = true
        this.errr21 = true
        this.errr22 = true
         this.errr25 = true
         this.errr27 = true
        this.errr28 = true
        this.errr31 = true
        this.errr32 = true
        this.errr33 = true
        this.errr34 = true
        this.errr35 = true
        this.errr36 = true
        this.errr37 = true
      }
      // if(this.SlitLampValue.approval_suitable == "" || this.SlitLampValue.approval_suitable == null){
      //   return false,
      //   this.snackbarText = "Please Fill the This is Cornea Suitable for Transplant.",
      //   this.snackbar = true
      // }
      
     
      this.SlitLampOS()
      
        // return false,
        // this.snackbarText = "Please Fill the OD .",
        // this.snackbar = true
    },
    rest(){
       if(this.SlitLampValue.stroma_type != 'Other'){
        this.SlitLampValue.approval_if_not_suitable_type_other = ''
       }
    },

    ClearAndIntact() {
      if (this.SlitLampValue.epithelium_clear_and_intact == 'Yes') {
          (this.SlitLampValue.heze = ''),
          (this.SlitLampValue.exposure = ''),
          (this.SlitLampValue.exposure_location = ''),
          (this.SlitLampValue.exposure_area = ''),
          (this.SlitLampValue.sloughing_location = ''),
          (this.SlitLampValue.sloughing_area = '')
      }
    },

    Exposure() {
      if (this.SlitLampValue.exposure == 'None') {
        ;(this.SlitLampValue.exposure_location = ''), (this.SlitLampValue.exposure_area = '')
      }
    },

    SloughingLocation() {
      if (this.SlitLampValue.sloughing_location == 'None') {
        this.SlitLampValue.sloughing_area = ''
      }
    },

    debRis() {
      if (this.SlitLampValue.debris == 'No') {
        this.SlitLampValue.debris_description = ''
      }
    },

    edEma() {
      if (this.SlitLampValue.edema == 'None') {
        this.SlitLampValue.edema_location = ''
      }
    },

    arCus() {
      if (this.SlitLampValue.arcus == 'None') {
        this.SlitLampValue.arcus_clear_zone = ''
      }
    },

    Opacities_One() {
      if (this.SlitLampValue.stroma_opacities1 == 'None') {
        this.SlitLampValue.stroma_opacities1_length = ''
        this.SlitLampValue.stroma_opacities1_location = ''
        this.SlitLampValue.stroma_opacities1_depth = ''
      }
    },

    Opacities_Two() {
      if (this.SlitLampValue.stroma_opacities2 == 'None') {
        this.SlitLampValue.stroma_opacities2_length = ''
        this.SlitLampValue.stroma_opacities2_location = ''
        this.SlitLampValue.stroma_opacities2_depth = ''
      }
    },

    StriaeFun() {
      if (this.SlitLampValue.striae == 'No') {
        this.SlitLampValue.striae_location = ''
        this.SlitLampValue.striae_relative = ''
      }
    },

    Folds() {
      if (this.SlitLampValue.descemets_folds == 'None') {
        this.SlitLampValue.descemets_folds_relative = ''
      }
    },

    deFects() {
      if (this.SlitLampValue.descemets_defects == 'None') {
        this.SlitLampValue.descemets_defects_description = ''
      }
    },

    StressLines() {
      if (this.SlitLampValue.endothelium_stress_lines == 'No') {
        this.SlitLampValue.endothelium_stress_lines_location = ''
        this.SlitLampValue.endothelium_stress_lines_relative = ''
      }
    },

    defectsEndo() {
      if (this.SlitLampValue.endothelium_defects == 'No') {
        this.SlitLampValue.endothelium_defects_description = ''
      }
    },

    cellDrop() {
      if (this.SlitLampValue.endothelium_cell_dropoult == 'None') {
        this.SlitLampValue.endothelium_cell_dropoult_location = ''
      }
    },

    ScleralFun() {
      if (this.SlitLampValue.add_info_scleral_rim_circumferentially == 'Yes') {
        this.SlitLampValue.add_info_scleral_rim_description = ''
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    onFileChange() {
      this.signatureObj = URL.createObjectURL(this.signature)
      console.log('img data 0 : ', this.signatureObj);
      const reader = new FileReader()
      reader.readAsDataURL(this.signature)
      reader.onload = e => {
        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.SignatureObj = null;
          this.Signature = null;
          return;
        }
        this.SlitLampValue.upload_form = e.target.result
      }
    },

    onFileChange1() {
      this.signatureObj1 = URL.createObjectURL(this.Getsignature)

      console.log('img data : ', this.signatureObj1);
      const reader = new FileReader()
      reader.readAsDataURL(this.Getsignature)
      reader.onload = e => {
        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.Getsignature = null;
          this.signatureObj1 = null;
          return;
        }
        this.SlitLampValue.specular_evaluation = e.target.result
      }
    },

    onFileChange2() {
      this.tissue_evaluation_upload_formObj = URL.createObjectURL(this.tissue_evaluation_upload_form)
      console.log('img data 2 : ', this.tissue_evaluation_upload_formObj);
      const reader = new FileReader()
      reader.readAsDataURL(this.tissue_evaluation_upload_form)
      reader.onload = e => {
        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.tissue_evaluation_upload_form = null;
          this.tissue_evaluation_upload_formObj = null;
          return;
        }
        this.SlitLampValue.tissue_evaluation_upload_form = e.target.result
      }
    },

    async getSlitLamp() {
      const DonorId = this.$route.params.donor_id
      const service = new SlitLampService()
      let response = await service.Slit_lampOS(DonorId)
      this.dateitem = response
      this.approvalNameEmail = response.medicalDrictor
      
      const result = response.medicalDrictor.map((item) => {
        this.approvalNames.push(item.name)
      })
      
      try {
        this.selected_yes_slit = JSON.parse(response.approval_if_suitable_type)
        this.selected_no_slit = JSON.parse(response.approval_if_not_suitable_type)
      } catch (error) {
        console.log(error)
      }
      this.SlitLampValue = response
    },

    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[7]
        this.formtype = this.permissionData[8]
      if(formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0"){
          this.readOnlyField1 = true
          this.saveBtn = false
        } 
      }else {
      this.totallist = 0
      }
      this.listLoading = false
    },

    async SlitLampOS() {
      if(this.SlitLampValue.approval_date_time == "Invalid date" || this.SlitLampValue.approval_date_time == "" || this.SlitLampValue.approval_date_time === null){
        this.SlitLampValue.approval_date_time = null
      }
      else{
        this.SlitLampValue.approval_date_time = moment.utc(this.SlitLampValue.approval_date_time).format('YYYY-MM-DD HH:mm')
      }
     
      const donorid = this.$route.params.donor_id
      const data = {
        tissue_evaluation_upload_form: this.SlitLampValue.tissue_evaluation_upload_form,
        OD_and_OS: 'OS',
        user_id: userId,
        donor_id: donorid,
        tissue_id: this.SlitLampValue.os_id,
        from_penlight_exam: this.SlitLampValue.from_penlight_exam,
        from_specular_evaluation: this.SlitLampValue.from_specular_evaluation,
        epithelium_clear_and_intact: this.SlitLampValue.epithelium_clear_and_intact,
        heze: this.SlitLampValue.heze,
        exposure: this.SlitLampValue.exposure,
        exposure_location: this.SlitLampValue.exposure_location,
        exposure_area: this.SlitLampValue.exposure_area,
        sloughing_location: this.SlitLampValue.sloughing_location,
        sloughing_area: this.SlitLampValue.sloughing_area,
        debris: this.SlitLampValue.debris,
        debris_description: this.SlitLampValue.debris_description,
        epithelium_clear_and_intact_commetns: this.SlitLampValue.epithelium_clear_and_intact_commetns,
        stroma_clear_and_intact: this.SlitLampValue.stroma_clear_and_intact,
        edema: this.SlitLampValue.edema,
        edema_location: this.SlitLampValue.edema_location,
        arcus: this.SlitLampValue.arcus,
        arcus_clear_zone: this.SlitLampValue.arcus_clear_zone,
        stroma_opacities1: this.SlitLampValue.stroma_opacities1,
        stroma_opacities1_length: this.SlitLampValue.stroma_opacities1_length,
        stroma_opacities1_location: this.SlitLampValue.stroma_opacities1_location,
        stroma_opacities1_depth: this.SlitLampValue.stroma_opacities1_depth,
        stroma_opacities2: this.SlitLampValue.stroma_opacities2,
        stroma_opacities2_length: this.SlitLampValue.stroma_opacities2_length,
        stroma_opacities2_location: this.SlitLampValue.stroma_opacities2_location,
        stroma_opacities2_depth: this.SlitLampValue.stroma_opacities2_depth,
        infiltrate: this.SlitLampValue.infiltrate,
        striae: this.SlitLampValue.striae,
        striae_location: this.SlitLampValue.striae_location,
        striae_relative: this.SlitLampValue.striae_relative,
        stroma_clear_and_compac_commetns: this.SlitLampValue.stroma_clear_and_compac_commetns,
        descemets_folds: this.SlitLampValue.descemets_folds,
        descemets_folds_relative: this.SlitLampValue.descemets_folds_relative,
        descemets_defects: this.SlitLampValue.descemets_defects,
        descemets_defects_description: this.SlitLampValue.descemets_defects_description,
        descemets_comments: this.SlitLampValue.descemets_comments,
        endothelium_stress_lines: this.SlitLampValue.endothelium_stress_lines,
        endothelium_stress_lines_location: this.SlitLampValue.endothelium_stress_lines_location,
        endothelium_stress_lines_relative: this.SlitLampValue.endothelium_stress_lines_relative,
        endothelium_defects: this.SlitLampValue.endothelium_defects,
        endothelium_defects_description: this.SlitLampValue.endothelium_defects_description,
        endothelium_cell_dropoult: this.SlitLampValue.endothelium_cell_dropoult,
        endothelium_cell_dropoult_location: this.SlitLampValue.endothelium_cell_dropoult_location,
        endothelium_polymegathism: this.SlitLampValue.endothelium_polymegathism,
        endothelium_pleomorphism: this.SlitLampValue.endothelium_pleomorphism,
        endothelium_comments: this.SlitLampValue.endothelium_comments,
        add_info_jaundice: this.SlitLampValue.add_info_jaundice,
        add_info_lens: this.SlitLampValue.add_info_lens,
        add_info_scleral_rim_evalution: this.SlitLampValue.add_info_scleral_rim_evalution,
        add_info_scleral_rim_circumferentially: this.SlitLampValue.add_info_scleral_rim_circumferentially,
        add_info_scleral_rim_description: this.SlitLampValue.add_info_scleral_rim_description,

        approval_suitable: this.SlitLampValue.approval_suitable,
        approval_if_suitable_type: JSON.stringify(this.selected_yes_slit),
        approval_if_not_suitable_type: JSON.stringify(this.selected_no_slit),
        stroma_type:this.SlitLampValue.stroma_type,
        approval_if_not_suitable_type_other: this.SlitLampValue.approval_if_not_suitable_type_other,
        approval_date_time: this.SlitLampValue.approval_date_time,
        approval_evaluation_comments: this.SlitLampValue.approval_evaluation_comments,
        upload_form: this.SlitLampValue.upload_form,
        specular_evaluation: this.SlitLampValue.specular_evaluation,

        specularDateTime:  moment.utc(this.SlitLampValue.specularDateTime).format('YYYY-MM-DD HH:mm'),
        specularTechnicianName:  this.SlitLampValue.specularTechnicianName,
        specularPachymetry:  this.SlitLampValue.specularPachymetry,
        approvalName:  this.SlitLampValue.approvalName,
        approval_evaluation_name: this.SlitLampValue.approval_evaluation_name,
        approval_evaluation_email: this.SlitLampValue.approval_evaluation_email
      }
      try {
        const response = await axios.post(`${baseUrl}tissueEvaluation/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == "true") {
          this.snackbarText = response.data.msg
          this.snackbar = true
          this.getSlitLamp()

          if(this.formtype.view != 0 && this.formtype.view != null){
            if(this.dateitem.od_id == '' || this.dateitem.od_id === null){
              if(this.SlitLampValue.approval_suitable != ""){
                this.$router.push({name:'TissueDetail'});
              }
            }
            else if(this.dateitem.od_id != null || this.dateitem.os_id != null){
              return false,
              this.snackbarText = "Please Fill the OD .",
              this.snackbar = true
            }
          }
          if(this.SlitLampValue.approval_suitable != ""){
            this.$router.push({name:'TissueDetail'});
          }

        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
