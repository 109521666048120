var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class Slitlamp {
    async Slit_lampOS(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueEvaluation/fetch_OS/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async Slit_lampOD(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueEvaluation/fetch_OD/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
}